<template>
  <div class="expSummaryReport container">

	<p class="font-weight-bold" align="center">Expense Summary Report</p>

	<form class="form">
		<CCard :color="'secondary'">
			<CCardBody>
				<div class="row">
					<label class="font-weight-bold ml-3 mr-2" for="fromDate">From:</label>
					<datepicker v-model="fromDate" name="fromDate"></datepicker>

					<label class="font-weight-bold ml-3 mr-2" for="toDate">To:</label>
					<datepicker v-model="toDate" name="toDate"></datepicker>		
					
					<CButton color="dark" class="ml-4" v-on:click.prevent="serverCall('getSummaryReport')">Get Report</CButton>

				</div>
			</CCardBody>
		</CCard>

	</form>	

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<div>
		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:row-style-class="rowStyleClassFn"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field != 'location'">
					<span>{{props.formattedRow[props.column.field].toLocaleString('en-IN')}}</span> 
				</span>
				<span v-else>
					{{props.formattedRow[props.column.field]}}
				</span>
			</template>
			<div slot="table-actions">
				<CButton color="dark" class="mr-3" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'expensesSummaryReport',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			columns: [],
			rows: [],

			fromDate: new Date(),
			toDate: new Date(),
			reportName: 'Expenses Summary Report',
			reportLongName: "",
			reportData: "",

			// disabledDates: {
			// 	//to: new Date(2016, 0, 5), // Disable all dates up to specific date
			// 	from: new Date(), // Disable all dates after specific date
			// },

			alert: null,
			loading: false,
		
		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
	},

	methods: {
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'blue'
			}
		},

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getSummaryReport":
								self.getSummaryReport(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Summary Report Data for the given date.
        getSummaryReport(authToken) {
            var self = this
			self.alert = null

            if (!this.fromDate || !this.toDate){
                self.alert = "Report dates not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Report Data..."

			var postJson = {
				'fromDate': this.getMidDay(this.fromDate),
				'toDate': this.getMidDay(this.toDate),
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var reportFromDateStr = this.fromDate.toLocaleDateString("en-US", options)
			var reportToDateStr = this.toDate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetExpensesSummaryReportData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("GetExpensesSummaryReportData - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.reportLongName = self.reportName + " From: " + reportFromDateStr + " To: " + reportToDateStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},
		

	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

